@import '../../../styles/colours.scss';

.auditLanding__tabButtonWrapper {
    display: flex;
    flex-wrap: wrap;
}

.auditLanding__tabButton {
    border-bottom: 2px solid #fff;

    button {
        color: lighten($primary_colour, 10%);
        font-weight: 500;
        font-size: 12px;
        padding: 10px 20px;
    }
    

    &.active {
        border-bottom-color: $primary_colour;

        button {
            color: $primary_colour;
        }
    }

    &:hover {
        border-bottom-color: lighten($primary_colour, 20%);
        button {
            color: lighten($primary_colour, 20%)
        }
    }
}