@import '../../../styles/colours.scss';

.deletePromoCode__button {
    width: 242px;
    height: 36px;
    position: relative;
}

.deletePromoCode__spinner {
    position: absolute;
    top: 8px;
    left: calc(50% - 0.625rem);
}
