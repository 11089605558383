@import '../../../styles/colours.scss';

.audit__table {
    width: 100%;
    border-left: 1px solid $primary_colour;
    border-top: 1px solid $primary_colour;

    th {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 1px solid $primary_colour;
        border-right: 1px solid $primary_colour;
        padding: 8px 4px;
        color: #fff;
        background: $primary_colour;
    }

    td {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        border-bottom: 1px solid $primary_colour;
        border-right: 1px solid $primary_colour;
        padding: 8px 4px;
    }
}