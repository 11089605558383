@keyframes rotateImg {
    12.5% {
      transform: rotateY(90deg);
    }
    
    25% {
      transform: rotateY(180deg);
    }
    
    37.5% {
        transform: rotateY(270deg);
    }
    
    50% {
      transform: rotateY(360deg);
    }
    
    62.5% {
      transform: rotateY(270deg);
    }
    
    75% {
       transform: rotateY(180deg);
    }
    
    87.5% {
      transform: rotateY(90deg);
    }
    
    100% {
      transform: rotateY(0);
    }
  }
  
  .loader__innerWrapper {
    perspective: 600px;
    perspective-origin: 0, 50%;
    max-width: 300px;
    display: block;
    line-height: 0;
    transition: transform 4s ease;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 0;
    animation-name: rotateImg;
    
    img {
      width: 100%;
      height: auto;
    }
}

.loader__wrapper {
    height: 200px;
    position: relative;
 
    > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}