@import '../../styles/colours.scss';

// Shake effect
@keyframes error_shake {
    0%, 100% { left: 0px;}
    20% , 60%{left: 5px;}
    40% , 80%{left: -5px;}
}

.login__form {
    border-top: 1px solid #f2f2f2;
}

.login__imgWrapper {
    max-width: 300px;
    text-align: center;
    margin: 0 auto;
    
    img {
        width: 100%;
        height: auto;
    }
}

.login__error {
    text-align: center;
    color: $error_colour;
    transition: all 0.25s ease-in;
    opacity: 1;
    &.default {
        opacity: 0;
    }
}

.login__submitWrapper {
    position: relative;
    height: 44px;
}

.login__submitButton {
    background-color: $primary_colour;
    transition: all 0.4s ease;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    position: absolute;

    &.error {
        animation-name: error_shake;
    }
}
