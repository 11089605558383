@import '../../../styles/colours.scss';

.pnFix__button {
    width: 142px;
    height: 36px;
    position: relative;
}

.pnFix__spinner {
    position: absolute;
    top: 8px;
    left: calc(50% - 0.625rem);
}
