@import '../../styles/colours.scss';

.general {
    padding: 20px;
}

.general__actionButtonWrapper {
    display: table;
    width: auto;

    > div {
        display: table-cell;
        vertical-align: middle;
        padding-right: 20px;
    }
}