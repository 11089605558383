@import '../../../styles/colours.scss';

.manualInvoiceLanding {
    padding: 20px;
}

.manualInvoiceLanding__searchInput {
    position: relative;
    padding: 2px;

    input {
        border: 1px solid $primary-colour;
    }
}

.manualInvoiceLanding__searchSubmitWrapper {
    position: absolute;
    top: 1px;
    right: 0;
    width: 72px;
}

.manualInvoiceLanding__table {
    width: 100%;
    border-top: 1px solid $primary-colour;
    border-bottom: 1px solid $primary-colour;
    border-left: 1px solid $primary-colour;
}

.manualInvoiceLanding__tableHeader {
    border-right: 1px solid $primary-colour;
    padding: 5px;
}

.manualInvoiceLanding__tableContent {
    border-top: 1px solid $primary-colour;
    border-right: 1px solid $primary-colour;
    padding: 5px;
}

.manualInvoiceLanding__actionButtonWrapper {
    display: table;
    width: auto;

    > div {
        display: table-cell;
        vertical-align: middle;
        padding-right: 20px;
    }
}