@import './colours.scss';

.button__primary {
    background-color: $primary-colour;

    &:hover {
        background-color: lighten($primary-colour, 10%);
    }
}

.button__secondary {
    background-color: lighten($primary-colour, 25%);

    &:hover {
        background-color: lighten($primary-colour, 35%);
    }
}
