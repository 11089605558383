@import '../../../styles/colours.scss';

.invoicePaymentError__table {
    width: 100%;
    border-left: 1px solid $primary_colour;
    border-top: 1px solid $primary_colour;

    th {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 1px solid $primary_colour;
        border-right: 1px solid $primary_colour;
        padding: 8px 4px;
        color: #fff;
        background: $primary_colour;
    }

    td {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        border-bottom: 1px solid $primary_colour;
        border-right: 1px solid $primary_colour;
        padding: 8px 4px;
    }
}

.invoicePaymentError__toggle {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid $primary_colour;
    border-radius: 50%;
    vertical-align: middle;

    &:hover {
        cursor: pointer;
    }

    &.active {
        background: $primary_colour;
    }
}

.invoicePaymentError__actionWrapper {
    position: fixed;
    top: 10px;
    right: 20px;

    > div {
        padding-left: 8px;
    }

    button {
        background: $primary_colour;
        color: #fff;

        &:hover {
            background: lighten($primary_colour, 10%);
        }

        &:focus {
            background: lighten($primary_colour, 10%);
        }

        &:disabled {
            background: #c6c4c4;
        }
    }
}

.invoicePaymentError__feedback {
    padding-bottom: 20px;
    position: relative;
}

.invoicePaymentError__feedbackClose {
    position: absolute;
    top: 6px;
    right: 5px;

    &:hover {
        cursor: pointer;
    }
}