.addBags__button {
    width: 142px;
    height: 36px;
    position: relative;
}

.addBags__spinner {
    position: absolute;
    top: 8px;
    left: calc(50% - 0.625rem);
}