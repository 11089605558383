@import '../../../styles/colours.scss';

.cancelOrder__button {
    width: 142px;
    height: 36px;
    position: relative;
}

.cancelOrder__spinner {
    position: absolute;
    top: 8px;
    left: calc(50% - 0.625rem);
}

.cancelOrder__modal {
    background-color: #fff;
}