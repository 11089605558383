@import '../../styles/colours.scss';

.menu {
    position: relative;
}

.menu__triggerWrapper {
    position: absolute;
    top: 10px;
    left: 4px;
    display: inline-block;

    &:hover {
        cursor: pointer;
    }
}

.menu__logo {
    text-align: center;
    padding: 10px 20px;

    img {
        display: inline-block;
        line-height: 0;
        width: 100%;
        height: auto;
        max-width: 200px;
    }
}

.menu__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 85%;
    max-width: 400px;
    text-align: center;
    transform: translateX(-100%);
    transition: all 0.25s ease-in;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 10;
    height: 100vh;
    background-color: rgb(5 50 89 / 90%);
    border-right: 1px solid $primary-colour;

    &.active {
        transform: translateX(0);
    }
}

.menu__item {
    background-color: $primary-colour;
    > a {
        display: block;
        padding: 14px;
        border-bottom: 1px solid #fff;
        color: #fff;
    }
    
    &:hover {
        background: lighten($primary-colour, 5%);
    }
}

.menu__closeWrapper {
    position: absolute;
    top: 10px;
    left: 4px;

    &:hover {
        cursor: pointer;
    }
}

.menu__imgWrapper {
    text-align: center;
    background: #fff;
    padding: 12px 20px;
    border-bottom: 1px solid $primary-colour;

    img {
        display: inline-block;
        width: 100%;
        max-width: 200px;
        height: auto;
    }
}
